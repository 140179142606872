import React from 'react'

import type { HomePageQuery } from '../../../generated/frontend'
import { getDataLayerScripts } from '../../../shared/data-layer'
import { getDFPScripts } from '../../../shared/dfp'
import { useBaseMetaTags } from '../../../shared/hooks/useBaseMetaTags'
import { useFullUrl } from '../../../shared/hooks/useFullUrl'
import type { PageInfo } from '../../../shared/types'
import { removeFalsy, removeUrlQueryStringAndFragment } from '../../../shared/utils'
import { WEBSITE_SCHEMA } from './constants/meta-tags'

export const useHomePageMetaTags = () => {
  const { getBaseMetaTags } = useBaseMetaTags()
  const { getDataLayerItems } = useDataLayerItems()
  const { getFullUrl } = useFullUrl()

  return {
    getMetaTags: (meta: Meta, imageUrl?: string) => [
      ...getBaseMetaTags({ meta, imageUrl }),
      ...getDataLayerItems(meta, getFullUrl()),
      ...generateSchemaFromMeta(meta),
      <script type="application/ld+json">{WEBSITE_SCHEMA}</script>,
    ],
  }
}

///////// IMPLEMENTATION /////////

type Meta = HomePageQuery['homePage']['meta']

const useDataLayerItems = () => ({
  getDataLayerItems(meta: Meta, fullUrl: string) {
    const { dataLayer = [] } = meta || {}
    const pageInfo: PageInfo = {
      vertical: 'which?-home',
      content_type: 'which?-home',
      wcdPageUrl: removeUrlQueryStringAndFragment(fullUrl),
    }

    return [
      ...getDataLayerScripts([...dataLayer, removeFalsy(pageInfo)]),
      ...getDFPScripts(dataLayer, pageInfo),
    ]
  },
})

const generateSchemaFromMeta = (meta: Meta) => [
  <script type="application/ld+json">{`
    {
      "@type": "WebPage",
      "name": "${meta?.title}",
      "description": "${meta?.desc}",
      "url": "https://www.which.co.uk",
      "@context": "https://schema.org"
    }
  `}</script>,
]
