import type { FunctionComponent } from 'react'
import React from 'react'

import { useHomePageQuery } from '../../generated/frontend'
import { Error, Loader, PageError, PageTemplate, ThemeProvider } from '../../shared'
import type { PageInfo } from '../../shared/types'
import { useHomePageComponents } from './hooks/useHomePageComponents'
import { useHomePageMetaTags } from './hooks/useHomePageMetaTags'

const HomePage: FunctionComponent = () => {
  const { loading, error, data } = useHomePageQuery()
  const { getComponents } = useHomePageComponents()
  const { getMetaTags } = useHomePageMetaTags()

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!data) {
    return <PageError pageName="Home" />
  }

  const { homePage } = data

  const { dataLayer = [] } = homePage.meta || {}

  const login = dataLayer.find((item) => item?.loginStatus)
  const pageInfo: PageInfo = {
    vertical: 'homepage',
    content_type: 'homepage',
    pageType: homePage.meta.pageType ?? '',
  }

  return (
    <ThemeProvider>
      <PageTemplate
        metaTags={getMetaTags(homePage.meta, homePage.imageUrl)}
        components={getComponents(homePage)}
        gamConfig={{ pageInfo, login }}
      />
    </ThemeProvider>
  )
}

export default HomePage
