import React from 'react'

import type {
  FeaturedLinks,
  HomePageQuery,
  MemberBenefits,
  ToolSignpost,
} from '../../../generated/frontend'
import { Homepage } from '../components'

export const useHomePageComponents = () => {
  return {
    getComponents: (data: HomePageQuery['homePage']) => {
      const { toolCTAs, featuredLinks, mostReadLinks, memberBenefits } = data

      return {
        Homepage: () => (
          <Homepage
            toolCTAs={toolCTAs as ToolSignpost[]}
            featuredLinks={featuredLinks as FeaturedLinks[]}
            mostReadLinks={mostReadLinks}
            memberBenefits={memberBenefits as MemberBenefits}
          />
        ),
      }
    },
  }
}
